import SlimSelect from 'slim-select'

/**
 * Initializes select elements with SlimSelect library if there is at least one select element with the class 'select'.
 *
 * @param {Object} params - The parameters for the function.
 */
export function Select(select) {
    if (document.querySelector(select)) {
        const selects = document.querySelectorAll(select)

        selects.forEach(ele => {
            const select = `#${ele.id}`
            SelectCustom(select);
        });
    }
}


export const SelectCustom = (select, options = {}) => {
    return new SlimSelect({
        select: select,
        settings: {
            placeholderText: 'Sélectionner une valeur',
            showSearch: true,
            allowDeselect: true,
            searchText: 'Aucun résultat',
            searchPlaceholder: 'Recherche ...',
            searchHighlight: true
        }, ...options
    })
}
